body {
  margin: 0;
}
.h-100 {
  height: 100%;
}
#hero {
	height: 90vh;
	background: #519ad3;
	background-image: url('/src/img/bg.svg');
	background-size: 55vw;
	position: top left;
	background-repeat: no-repeat;
}
.main {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.logo {
  margin-top: 10vw;
  max-width: 100%;
}
.title {
  color: #fff;
  margin-top: 30px;
	font-family: 'Barlow Condensed';
	font-weight: 600;
	font-size: 75px;
	margin-bottom: 0;
  text-align: center;
}
#contact {
  background: #0b4e82;
	min-height: 10vh;
}
.booking-wrapper {
	background: #fff;
	border-radius: 8px;
	padding: 50px 0px;
	position: relative;
	top: -100px;	
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 925px;
}
.booking-title {
	color: #4F4E4E;
	font-size: 24px;
	line-height: 30px;
	word-spacing: 5px;
  text-align: center;
  font-weight: 400;
  font-family: 'Merriweather Sans';
}
.steps-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 35px;
  margin-bottom: 35px;
}
.calendly-wrapper {
  width: 110%;
  position: relative;
  min-height: 650px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.calendly-wrapper div {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 110%;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}
.social-wrapper {
  display: flex;
  gap: 50px;
  margin-top: 50px;
}
.social-icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.contact-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.contact-link {
  color: #fff;
  text-decoration: none;
  font-size: 38px;
  display: block;
  margin-top: 50px;
  font-family: 'Barlow';
}
.logo-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 167px;
  padding-bottom: 80px;
}
.logo-wrapper img {
  max-width: 100%;
}
.icon-disabled path {
  fill: #DBDBDB
}
.icon-enabled path {
  fill: #4F4E4E;
}
.btn-cta {
  width: 250px;
  border: 2px solid #FFE600;
  height: 70px;
  position: absolute;
  background-color: #FFE600;
  color: #002F43;
  font-size: 22px;
  font-family: 'Merriweather Sans';
  font-weight: 700;
  word-spacing: 3px;
  bottom: -35px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: none;
}
.arrow-mobile {
  display: none;
}

@media screen and (max-width: 960px) {
  #hero {
    background-size: 80vw;
  }
  .calendly-wrapper {
    display: none;
  }
  .booking-wrapper {
    height: 250px;
    top: -150px;
  }
  .booking-title {
    padding: 0px 20px;
    margin-bottom: 20px;
  }
  .arrow-desktop {
    display: none;
  }
  .arrow-mobile {
    display: block;
  }
  .btn-cta {
    display: block;
  }
  .title {
    font-size: 62px;
  }
  .contact-link {
    font-size: 28px;
  }
  .social-icon {
    width: 35px;
  }
  .social-wrapper {
    gap: 35px;
  }
}

@media screen and (max-width: 360px) {
  .contact-link {
    font-size: 20px;
  }
  .icon-step{
    width: 25px;
  }
  .btn-cta {
    height: 50px;
    width: 150px;
    font-size: 16px;
    bottom: -25px;
  }
}